import { Auth, User } from "@firebase/auth";
import {
  HomeIcon,
  PersonIcon,
  SignOutIcon,
  TableIcon,
  UnverifiedIcon,
  VerifiedIcon,
} from "@primer/octicons-react";
import { Avatar } from "components";
import { fb } from "modules";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";

const Sidebar = ({ user }: { user: User | null }) => {
  const [verify, setVerify] = useState("Please verify email");
  const [verified, setVerified] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const customEmailHandle = () => {
    if (user && user.emailVerified) {
      setVerified(true);
    } else {
      const getParameterByName = (name: string) => {
        const url = new URL(window.location.href);
        return url.searchParams.get(name);
      };

      const handleVerifyEmail = async (
        auth: Auth,
        actionCode: string | null,
        continueUrl: string,
        lang: string
      ) => {
        try {
          if (actionCode) {
            await fb.applyActionCode(fb.auth, actionCode);
            setVerify("Email verified");
            navigate("/");
            setVerified(true);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            setVerified(false);
          }
        } catch (error) {
          setTimeout(() => {
            setVerify("Email verification failed");
          }, 2000);
        }
      };
      // Get the action to complete.
      const mode = getParameterByName("mode");
      // Get the one-time code from the query parameter.
      const actionCode = getParameterByName("oobCode");
      // (Optional) Get the continue URL from the query parameter if available.
      const continueUrl = getParameterByName("continueUrl") || "/";
      // (Optional) Get the language code if available.
      const lang = getParameterByName("lang") || "en";
      // Handle the user management action...
      switch (mode) {
        case "resetPassword":
          // Display reset password handler and UI.
          // handleResetPassword(auth, actionCode, continueUrl, lang);
          break;
        case "recoverEmail":
          // Display email recovery handler and UI.
          // handleRecoverEmail(auth, actionCode, lang);
          break;
        case "verifyEmail":
          // Display email verification handler and UI.
          handleVerifyEmail(fb.auth, actionCode, continueUrl, lang);
          break;
        default:
        // Error: invalid mode.
      }
    }
  };

  useEffect(() => {
    if (user?.displayName === null) {
      navigate("/account/profile/name");
    } else if (user?.email === null) {
      navigate("/account/profile/email");
    }
  }, [user, navigate]);

  useEffect(customEmailHandle, [user, navigate]);

  const verifyEmail = async () => {
    try {
      const user = fb.auth.currentUser as any;
      fb.sendEmailVerification(user)
        .then(() => {
          // Email sent...
          setVerify("Verification email sent");
        })
        .catch((error) => {
          // An error happened...
          const message =
            error.code === "auth/requires-recent-login"
              ? "Please sign in again"
              : error.code === "auth/too-many-requests"
              ? "Too many requests"
              : error.code === "auth/user-disabled"
              ? "User disabled"
              : "An error occurred, retry";
          setVerify(message);
        });
    } catch (error) {}
  };

  return (
    <section className="inner-nav">
      <section className="user-summary">
        <section className="side-top">
          <Avatar
            displayName={user && user.displayName ? user.displayName : ""}
          />
          <div>
            <div className="side-name">
              {user && user.displayName ? user.displayName : "No name"}
            </div>
            <div className="side-email">
              <div
                style={
                  (user && user.emailVerified) || verified
                    ? {}
                    : { color: "#dbab09" }
                }
                className="side-email-icon">
                {(user && user.emailVerified) || verified ? (
                  <VerifiedIcon />
                ) : (
                  <UnverifiedIcon />
                )}
              </div>
              <Link className="side-email-text" to="/account/profile">
                {(user && user.emailVerified) || verified
                  ? "Verified"
                  : "Unverified"}
              </Link>
            </div>
          </div>
        </section>
        <div style={user && user.email ? {} : { display: "none" }}>
          <div
            style={
              (user && user.emailVerified) || verified
                ? { display: "none" }
                : {}
            }
            className="verify-email">
            <span>{verify}</span>
            <button
              style={
                verify === "Email verified || Verification email sent"
                  ? { display: "none" }
                  : {}
              }
              type="button"
              onClick={verifyEmail}>
              Verify
            </button>
          </div>
        </div>
      </section>

      <section className="sidebar-inner-top">
        <NavLink to="/" className="navigate">
          <span className="navigate-inner">
            {" "}
            <HomeIcon size={24} /> <span className="label-text">Home</span>
          </span>
        </NavLink>
        <NavLink
          to="/transactions"
          className={`navigate ${
            location.pathname.includes("/transactions") ? "active" : ""
          }`}>
          <span className="navigate-inner">
            {" "}
            <TableIcon size={24} /> <span className="label-text">Activity</span>
          </span>
        </NavLink>
        <NavLink
          to="/account"
          className={`navigate ${location.pathname.includes(
            "/account"
          )} ? "active" : ""`}>
          <span className="navigate-inner">
            {" "}
            <PersonIcon size={24} /> <span className="label-text">Account</span>
          </span>
        </NavLink>
        <div className="navigate" onClick={() => fb.auth.signOut()}>
          <span className="navigate-inner">
            {" "}
            <SignOutIcon size={24} />{" "}
            <span className="label-text">Sign out</span>
          </span>
        </div>
      </section>
    </section>
  );
};

export default Sidebar;
