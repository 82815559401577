import { EyeIcon, ReplyIcon, XIcon } from "@primer/octicons-react";
import { useContext, useState } from "react";
import { TokenItem, TokenPlaceholder } from "..";
import "../../assets/styles/purchase.css";
import { AuthContext, MainContext } from "../../contexts";
import { TokenResponse } from "../../models";
import { dateFormatter } from "../../modules";

const PurchaseList = ({ title = "Purchased" }: { title: string }) => {
  const { hide, setHide, currentUser } = useContext(AuthContext);
  const { sentTokens, receivedTokens, loadingTokens } = useContext(MainContext);
  const [currentToken, setCurrentToken] = useState<any | TokenResponse>();
  const [showMore, setShowMore] = useState(false);
  // Handle back...
  const handleBack = () => {
    setShowMore(false);
  };
  // Get Links...
  const tokens =
    title === "Purchased" && sentTokens
      ? sentTokens
      : receivedTokens
      ? receivedTokens
      : [];
  const list = tokens.length > 0 ? tokens : [];
  const Items = list.map((item: TokenResponse) => (
    <TokenItem
      key={item.id}
      data={item}
      setCurrentToken={setCurrentToken}
      showMore={showMore}
      setShowMore={setShowMore}
    />
  ));
  return (
    <section
      style={hide ? { display: "none" } : {}}
      className="purchase-overlay">
      <section
        style={hide ? { display: "none" } : {}}
        className="purchase-list fade-in">
        <header className="purchase-header">
          <div className="purchase-title">{title}</div>
          <div className="purchase-icon" onClick={() => setHide(true)}>
            <XIcon size={24} />
          </div>
        </header>
        <section className="voucher-list-items">
          {loadingTokens ? (
            <TokenPlaceholder />
          ) : Items.length === 0 ? (
            <div className="no-links">
              <span style={{ marginLeft: 5 }}>
                You have <b>0</b> Token Vouchers
              </span>
            </div>
          ) : (
            <div className="link-items">
              {showMore ? (
                <div className="voucher-info fade-in">
                  <div className="note-desc">
                    <div
                      className="voucher-token-header"
                      onClick={handleBack}
                      style={{ color: "#259c8c", fontWeight: 500 }}>
                      <span className="voucher-info-back">
                        {" "}
                        <ReplyIcon size={16} />{" "}
                        <span style={{ marginLeft: 5 }}>Back</span>
                      </span>
                    </div>
                    <div className="note-desc-text">
                      {currentToken.from.phone === currentUser?.phoneNumber
                        ? `A KSH ${currentToken.value} voucher token, balance KSH ${currentToken.balance}`
                        : currentToken.note}
                    </div>
                    <div>
                      <div>
                        {currentToken.to.length > 0 ? (
                          <span>
                            {currentToken.to.includes(
                              currentUser?.phoneNumber
                            ) ? (
                              <span className="voucher-tag-label">
                                <EyeIcon size={16} />{" "}
                                <span className="voucher-tag-label-text">
                                  {currentToken.from.phone}
                                </span>
                              </span>
                            ) : (
                              <span className="voucher-tag-label">
                                <EyeIcon size={16} />{" "}
                                <span className="voucher-tag-label-text">
                                  {currentToken.to.join(",")}
                                </span>
                              </span>
                            )}
                          </span>
                        ) : (
                          <span className="voucher-tag-label">
                            <EyeIcon size={16} />
                            <span className="voucher-tag-label-text">All</span>
                          </span>
                        )}
                      </div>
                      <div className="voucher-date-created">
                        <span>Date :</span>
                        <span>
                          {dateFormatter(currentToken.date_created).created_on},{" "}
                          {dateFormatter(currentToken.date_created).created_at},{" "}
                          {dateFormatter(currentToken.date_created).time_since}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                Items
              )}
            </div>
          )}
        </section>
      </section>
    </section>
  );
};

export default PurchaseList;
