import { XIcon } from "@primer/octicons-react";
import { CSSProperties, useEffect, useState } from "react";
import styled from "styled-components";
import "./alert.css";

interface AlertInterface {
  type: "success" | "warning" | "info" | "error";
  message?: string | JSX.Element;
  style?: CSSProperties;
  onClose?: () => void;
  show?: boolean;
  showIcon?: boolean;
  children?: any;
  className?: string;
}

const AlertComponent = ({
  type = "success",
  message = "Default message",
  show = true,
  onClose = () => {},
  style = {},
  showIcon = true,
  children,
  className,
}: AlertInterface) => {
  const [typeStyle, setTypeStyle] = useState<CSSProperties>({});
  const [open, setOpen] = useState<boolean>(true);
  useEffect(() => {
    switch (type) {
      case "success":
        setTypeStyle({
          color: "#259c8c",
          backgroundColor: "#e6ffed",
          border: "1px solid #c8e1ff",
        });
        break;
      case "warning":
        setTypeStyle({
          color: "#b08800",
          backgroundColor: "#fff5b1",
          border: "1px solid #ffd33d",
        });
        break;
      case "error":
        setTypeStyle({
          color: "#d73a49",
          backgroundColor: "#ffeef0",
          border: "1px solid #ffdce0",
        });
        break;
      case "info":
        setTypeStyle({
          color: "#0366d6",
          backgroundColor: "#f1f8ff",
          border: "1px solid #c8e1ff",
        });
        break;

      default:
        setTypeStyle({
          color: "#259c8c",
          backgroundColor: "#e6ffed",
          border: "1px solid #c8e1ff",
        });
    }
    setOpen(show);
  }, [type, show]);

  return open ? (
    <section
      className={className + " alert-message"}
      style={{
        ...typeStyle,
        ...style,
      }}>
      <div className="alert-message-text">{children ? children : message}</div>
      {showIcon ? (
        <span
          onClick={() => {
            setOpen(false);
            onClose();
          }}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
          }}>
          <XIcon size={20} />
        </span>
      ) : null}
    </section>
  ) : null;
};

const Alert = styled(AlertComponent)``;

export default Alert;
