import "./slider.css";
import nairobi from "../../assets/images/campaigns/nairobi.jpeg";
import date from "../../assets/images/campaigns/date.jpeg";
import coffee from "../../assets/images/campaigns/coffee.jpeg";
import gifting from "../../assets/images/campaigns/gifting.jpeg";
import clothing from "../../assets/images/campaigns/clothing.jpeg";
import vacation from "../../assets/images/campaigns/vacation.jpeg";
import online from "../../assets/images/campaigns/online.jpeg";
import concerts from "../../assets/images/campaigns/concerts.jpeg";
import lunch from "../../assets/images/campaigns/lunch.jpeg";
import birthday from "../../assets/images/campaigns/birthday.jpeg";
import friends from "../../assets/images/campaigns/friends.jpeg";
import offers from "../../assets/images/campaigns/offers.jpeg";

// Sample data for the slider
const campaigns = [
  {
    id: 1,
    title: "Nairobi",
    description: "Limited time offer",
    image: nairobi,
  },
  {
    id: 2,
    title: "Dating",
    description: "Special occasion",
    image: date,
  },
  {
    id: 3,
    title: "Coffee",
    description: "Special occasion",
    image: coffee,
  },
  {
    id: 4,
    title: "Gifting",
    description: "Special occasion",
    image: gifting,
  },
  {
    id: 5,
    title: "Clothing",
    description: "Special occasion",
    image: clothing,
  },
  {
    id: 6,
    title: "Vacation",
    description: "Special occasion",
    image: vacation,
  },
  {
    id: 7,
    title: "Shopping",
    description: "Special occasion",
    image: online,
  },
  {
    id: 8,
    title: "Concerts",
    description: "Special occasion",
    image: concerts,
  },
  {
    id: 9,
    title: "Lunch",
    description: "Special occasion",
    image: lunch,
  },
  {
    id: 10,
    title: "Birthday",
    description: "Special occasion",
    image: birthday,
  },
  {
    id: 11,
    title: "Friends",
    description: "Special occasion",
    image: friends,
  },
  {
    id: 12,
    title: "Offers",
    description: "Special occasion",
    image: offers,
  },
];

// Sort by title...
campaigns.sort((a, b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
});

const Slider = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <section style={style} className="slider">
      <div className="slider-title">Endless Choices</div>
      <div className="slider-items">
        {campaigns.map((campaign) => (
          <div key={campaign.id} className="slider-item">
            <img src={campaign.image} alt={campaign.title} loading="eager" />
            <div className="slider-item-box">
              <div className="slider-item-box-label">{campaign.title}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Slider;
