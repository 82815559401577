import { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "contexts";
import { ArrowLeftIcon, ChevronRightIcon } from "@primer/octicons-react";
import BalanceComponent from "components/balance";
import { Summary, Sidebar } from "components";
import "./account.css";

const AccountSettingsPage = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    document.title = `Settings - Your Profile`;
  }, []);

  return (
    <section className="account-page">
      <main>
        <section className="flex">
          <section className="flex-nav">
            <Sidebar user={currentUser} />
          </section>
          <section className="flex-left">
            <section className="inner-left">
              <div className="left-header">
                <NavLink className="account-page-flexbox" to="/account">
                  <ArrowLeftIcon size={24} />
                  <div className="account-page-back">
                    {" "}
                    <div className="left-h1">Profile</div>
                  </div>
                </NavLink>
              </div>
              <section className="account">
                <section>
                  <Link className="account-item" to="/account/profile/name">
                    <div className="account-item-left">
                      <div className="account-label">Name</div>
                      <div className="account-name">
                        {currentUser?.displayName || "No name"}
                      </div>
                    </div>
                    <div className="account-item-right">
                      <ChevronRightIcon size={24} />
                    </div>
                  </Link>
                  <Link className="account-item" to="/account/profile/email">
                    <div className="account-item-left">
                      <div className="account-label">Email</div>
                      <div className="account-name">
                        {currentUser?.email || "No email"}
                      </div>
                    </div>
                    <div className="account-item-right">
                      <ChevronRightIcon size={24} />
                    </div>
                  </Link>
                </section>
              </section>
            </section>
          </section>
          <section className="flex-right">
            <section className="flex-container">
              <section className="right-top">
                <div className="top-inner">
                  <BalanceComponent />
                </div>
              </section>
              <Summary title="Purchased" />
            </section>
          </section>
        </section>
      </main>
    </section>
  );
};

export default AccountSettingsPage;
