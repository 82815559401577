import { AuthContextData } from "models";
import { fb } from "modules";
import React, { useEffect, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";

const AuthContext = React.createContext<AuthContextData>({
  idToken: "",
  hostAPI: "https://dev-api.vouchpal.com",
  hide: false,
  setHide: () => null,
  signin: false,
  setSignin: () => null,
  phone: null,
  setPhone: () => null,
  loading: false,
  setLoading: () => null,
  currentUser: null,
});

const AuthProvider = ({ children }: any) => {
  const [currentUser, setCurrentUser] = useState<any>();
  const [pending, setPending] = useState(true);
  const [idToken, setIdToken] = useState("");
  const [signin, setSignin] = useState(false);
  const [hostAPI, setHostAPI] = useState("https://dev-api.vouchpal.com");
  const [hide, setHide] = useState(true);
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fb.onAuthStateChanged(fb.auth, async (user: any) => {
      try {
        if (user) {
          setCurrentUser(user);
          const token = await user.getIdToken(false);
          setIdToken(token);
          setPending(false);
        } else {
          setPending(false);
          setCurrentUser(null);
        }
      } catch (error) {
        setPending(false);
        setCurrentUser(null);
      }
    });
    return () => {
      setIdToken("");
      setCurrentUser(null);
      setPending(true);
    };
  }, []);

  useEffect(() => {
    const hostname = window.location.hostname;
    const api =
      hostname === "localhost" || "dev.vouchpal.com"
        ? "https://dev-api.vouchpal.com"
        : hostname === "staging.vouchpal.com"
        ? "https://staging-api.vouchpal.com"
        : "https://api.vouchpal.com";
    setHostAPI(api);
    return () => {
      setHostAPI(api);
    };
  }, []);

  return pending ? (
    <section className="load-page fade-in">
      <BounceLoader color="rgb(54, 215, 183)" size={75} />
    </section>
  ) : (
    <AuthContext.Provider
      value={{
        currentUser,
        idToken,
        hostAPI,
        hide,
        setHide,
        signin,
        setSignin,
        phone,
        setPhone,
        loading,
        setLoading,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
