import { LineLoader } from "components";
import { AuthContext } from "contexts";
import { fb } from "modules";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { apiService, authService, coreService } from "services";
import { InvalidInput } from "../signin/signin";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

const Signup = () => {
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const [processing, setProcessing] = useState(false);
  const [step, setStep] = useState(1);
  const [validInput, setValidInput] = useState({
    phone: true,
    code: true,
  });
  const [otpMessage, setOtpMessage] = useState("");
  const [accountExists, setAccountExists] = useState(false);
  const [phone, setPhone] = useState("");

  useEffect(() => {
    document.title = `Vouchpal - Sign up for an account`;
    fb.auth.useDeviceLanguage();
  }, []);

  const handlePhoneChange = (event: any) => {
    event.preventDefault();
    const { value } = event.target;
    setAccountExists(false);
    setValidInput((prev) => ({ ...prev, phone: false }));
    if (value.length > 0) {
      const phone = coreService.formatPhoneNumber({
        phoneNumber: value,
        countryCode: "KE",
      });
      if (phone.isValid) {
        setPhone(phone.phoneNumber);
        apiService.checkPhoneNumber(phone.phoneNumber, (exists) => {
          if (exists) {
            setAccountExists(true);
            setProcessing(false);
            setValidInput((prev) => ({ ...prev, phone: false }));
          } else {
            setValidInput((prev) => ({ ...prev, phone: true }));
            setAccountExists(false);
          }
        });
      } else {
        setValidInput((prev) => ({ ...prev, phone: false }));
      }
    } else {
      setValidInput((prev) => ({ ...prev, phone: false }));
    }
  };

  const handleGetOTP = (event: any) => {
    event.preventDefault();
    setProcessing(true);
    authService.sendOTP(phone, (res) => {
      setOtpMessage(res.message);
      setStep(res.ok ? 3 : 1);
      setProcessing(false);
    });
  };

  const handleProceed = (event: any) => {
    event.preventDefault();
    const { tel } = event.target.elements;
    setProcessing(true);
    const phone = coreService.formatPhoneNumber({
      phoneNumber: tel.value,
      countryCode: "KE",
    });
    if (phone.isValid) {
      setStep(2);
      setProcessing(false);
      setPhone(phone.phoneNumber);
    } else {
      setProcessing(false);
      setValidInput((prev) => ({ ...prev, phone: false }));
    }
  };

  const handleVerifyOTP = (event: any) => {
    event.preventDefault();
    const { otp } = event.target.elements;
    setProcessing(true);
    const code = otp.value;
    if (code && code.length === 6) {
      authService.verifyOTP(code, (res) => {
        if (res.ok) {
          navigate("/");
        } else {
          setValidInput((prev) => ({ ...prev, code: false }));
          setProcessing(false);
        }
      });
    } else {
      setValidInput((prev) => ({ ...prev, code: false }));
      setProcessing(false);
    }
  };

  return currentUser ? (
    <Navigate to="/" />
  ) : (
    <section className="auth-page">
      <LineLoader show={processing} />
      <section className="auth-page-left"></section>
      <section className="auth-page-right fade-in">
        {step === 1 ? (
          <form className="auth-form" onSubmit={handleProceed}>
            <div className="auth-form-header">
              <h1 className="auth-form-header-title">Sign up</h1>
              <div className="auth-form-header-subtitle">
                Enter your phone number to continue. We will send you an OTP to
                verify your phone number.
              </div>
            </div>
            <div className="auth-form-group">
              <input
                type="tel"
                id="tel"
                name="tel"
                autoComplete="tel"
                placeholder="e.g 0712345678"
                onChange={handlePhoneChange}
                required
              />
              {validInput.phone ? null : (
                <InvalidInput
                  message={
                    accountExists
                      ? "Phone number already in use, please use a different number"
                      : "Phone number is invalid"
                  }
                />
              )}
            </div>
            <div className="auth-form-group">
              <button
                disabled={processing || accountExists || !validInput.phone}
                className="auth-form-btn"
                type="submit">
                {processing ? "Please wait" : "Continue"}
              </button>
            </div>
            <div className="auth-form-footer">
              <div className="auth-form-footer-text">
                Already have an account?{" "}
                {accountExists ? (
                  <span
                    className="auth-link"
                    onClick={() =>
                      navigate("/signin", {
                        state: { phone: phone },
                      })
                    }>
                    Sign in
                  </span>
                ) : (
                  <Link to="/signin" className="auth-link">
                    Sign in
                  </Link>
                )}
              </div>
            </div>
          </form>
        ) : step === 2 ? (
          <div className="auth-confirm">
            <div className="auth-form-header">
              <h1 className="auth-form-header-title">Sign up</h1>
              <div className="auth-form-header-subtitle">
                Are you sure you want to sign up with this phone number?
              </div>

              <div className="auth-form-phone-title">
                {
                  coreService.formatPhoneNumber({
                    phoneNumber: phone,
                    countryCode: "KE",
                  }).phoneNumber
                }
              </div>
              <div className="auth-btn-group">
                <button
                  disabled={processing}
                  className="auth-form-btn"
                  type="button"
                  onClick={handleGetOTP}>
                  {processing ? "Please wait" : "Yes, continue"}
                </button>
                <button
                  className="auth-form-btn auth-form-btn-cancel"
                  type="button"
                  onClick={() => {
                    setStep(1);
                    authService.clearRecaptchaVerifier();
                  }}>
                  Cancel
                </button>
              </div>
              <div className="auth-form-terms">
                By signing up you agree to our{" "}
                <Link to="/terms" className="auth-link">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/privacy" className="auth-link">
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <form
            id="verify-otp-form"
            className="auth-form"
            onSubmit={handleVerifyOTP}>
            <div className="auth-form-header">
              <h1 className="auth-form-header-title">Verify OTP</h1>
            </div>
            <div className="auth-form-group">
              <label className="auth-form-label" htmlFor="otp">
                {otpMessage}
              </label>
              <input
                type="text"
                id="otp"
                name="otp"
                placeholder="e.g 123456"
                required
                maxLength={6}
                minLength={6}
              />
              {validInput.code ? null : (
                <InvalidInput message="Please provide a valid OTP code" />
              )}
            </div>
            <div className="auth-btn-group">
              <button
                disabled={processing}
                className="auth-form-btn"
                type="submit">
                {processing ? "Please wait" : "Verify OTP"}
              </button>
              <button
                className="auth-form-btn auth-form-btn-cancel"
                type="button"
                onClick={() => {
                  setStep(1);
                  authService.clearRecaptchaVerifier();
                }}>
                Back
              </button>
            </div>
          </form>
        )}
      </section>
      <div id="recaptcha-container">
        <div id="recaptcha"></div>
      </div>
    </section>
  );
};

export default Signup;
