import "assets/styles/loader.css";

const Loader = ({
  size = 60,
  stroke = "#70c542",
  strokeWidth = 3,
}: {
  size?: number;
  stroke?: string;
  strokeWidth?: number;
}) => {
  return (
    <div style={{ width: size, height: size }} className="loader">
      <svg className="circular-loader" viewBox="25 25 50 50">
        <circle
          className="loader-path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </svg>
    </div>
  );
};

export default Loader;
