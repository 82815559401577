import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  CircleIcon,
  GiftIcon,
  SyncIcon,
} from "@primer/octicons-react";
import { Alert, Loader } from "components";
import { CounterLabel } from "components/commons/counter-label";
import { GroupedRadios } from "components/commons/radio";
import { AuthContext } from "contexts";
import { IBuyPayload, IVoucher } from "models/api.model";
import { useContext, useEffect, useState } from "react";
import { BarLoader, ClockLoader } from "react-spinners";
import { apiService, coreService, dateService } from "services";
import "./buy.css";

const Buy = ({ preview, setPreview }: { preview: any; setPreview: any }) => {
  const { idToken, hostAPI, currentUser } = useContext(AuthContext);
  const [vouchers, setVouchers] = useState<IVoucher[]>([]);
  const [fetchingVouchers, setFetchingVouchers] = useState(true);
  const [step, setStep] = useState(0);
  const [voucher, setVoucher] = useState<IBuyPayload>({
    first_step: true,
    amount: 0,
    currency: "KES",
    payment: {
      type: "mpesa",
      provider: "safaricom",
      phone: "",
    },
    maturity: {
      date: "2022-01-01",
    },
    recipients: [],
    partner: {
      id: "",
      name: "",
    },
    sender: "",
    note: "",
    use: "restricted",
    type: "open",
  });
  const [subtitle, setSubtitle] = useState("All Vouchers");
  const [createDate, setCreateDate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({
    name: "",
    label: "",
  });
  const [mpesaPhone, setMpesaPhone] = useState("");
  const [recepientPhone, setRecepientPhone] = useState("");
  const [verifyPayment, setVerifyPayment] = useState({
    processing: false,
    success: true,
    message: "Please wait while we verify your payment",
  });
  const [mpesaVerify, setMpesaVerify] = useState({
    transaction_ref: "",
    token_ref: "",
    merchant_request_id: "",
    checkout_request_id: "",
  });
  const [alert, setAlert] = useState<{
    show: boolean;
    type: "success" | "error" | "warning";
    message: string;
  }>({
    show: false,
    type: "success",
    message: "",
  });

  useEffect(() => {
    document.title = "Home - Buy Voucher";
    if (voucher.type) setCreateDate(dateService.today("date"));
    if (currentUser && currentUser.phoneNumber)
      setMpesaPhone(currentUser.phoneNumber);
  }, [voucher.type, currentUser]);

  useEffect(() => {
    (async () => {
      try {
        if (preview.buy && idToken) {
          const res = await apiService.getData({
            method: "get",
            url: `${hostAPI}/vouchers`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          });
          if (res.ok) {
            const filtered = res.data.filter(
              (voucher: { category: { name: string } }) =>
                voucher.category.name === preview.category.name
            );
            setVouchers(filtered);
          } else {
            setVouchers([]);
          }
          setFetchingVouchers(false);
        }
      } catch (error) {
        setVouchers([]);
        setFetchingVouchers(false);
      }
    })();
    return () => {
      setVouchers([]);
    };
  }, [preview.category, preview.buy, hostAPI, idToken, preview]);

  useEffect(() => {
    switch (step) {
      case 0:
        setSubtitle("All Vouchers");
        break;
      case 1:
        setSubtitle("Select Voucher");
        break;
      case 2:
        setSubtitle("Payment Method");
        break;
      case 3:
        setSubtitle("Confirm Details");
        break;
      case 4:
        setSubtitle("Processing Payment");
        break;
      default:
        break;
    }
  }, [step]);

  const handleSetVoucher = (event: any) => {
    event.preventDefault();
    const el = event.target.elements;
    switch (voucher.type) {
      case "open":
        setVoucher((prev) => ({
          ...prev,
          type: "open",
          amount: Number(el.amount.value),
          maturity: {
            date: el.maturity.value,
          },
          sender: currentUser?.phoneNumber ?? "",
        }));
        break;
      case "closed":
        setVoucher((prev) => ({
          ...prev,
          type: "closed",
          amount: Number(el.amount.value),
          maturity: {
            date: el.maturity.value,
          },
          recipients: [
            coreService.formatPhoneNumber({
              phoneNumber: el.recipient.value || "",
              countryCode: "KE",
            }).phoneNumber,
          ],
          use: el.use.value,
          sender: currentUser?.phoneNumber ?? "",
        }));
        break;
    }
    setStep(2);
  };

  const handleSetPayment = (event: any) => {
    event.preventDefault();
    setVoucher((prev) => ({
      ...prev,
      payment: {
        ...prev.payment,
        phone: mpesaPhone,
      },
    }));
    setStep(3);
  };

  const handlePayment = async (event: any) => {
    event.preventDefault();
    try {
      setStep(4);
      setAlert((prev) => ({ ...prev, show: false }));
      setVerifyPayment((prev) => ({
        ...prev,
        processing: true,
      }));
      await apiService.buyToken(voucher, idToken, (res) => {
        if (res.ok) {
          setVerifyPayment((prev) => ({
            ...prev,
            processing: false,
            success: true,
            message: res.message,
          }));
          setMpesaVerify(res.data);
        } else {
          setAlert({
            show: true,
            type: "error",
            message: res.message,
          });
          setStep(3);
        }
      });
    } catch (error: any) {
      console.log("Error :", error);
      setStep(3);
      setAlert({
        show: true,
        type: "error",
        message:
          error.message ||
          "Sorry, an error occured while processing, try again",
      });
    }
  };

  const handlePaymentVerification = async () => {
    try {
      setStep(5);
      setVerifyPayment((prev) => ({
        ...prev,
        processing: true,
      }));
      await apiService.buyToken(mpesaVerify, idToken, (res) => {
        console.log("Res :", res);
        if (res.ok) {
          setVerifyPayment((prev) => ({
            ...prev,
            processing: false,
            success: true,
            message: res.message,
          }));
        } else {
          setVerifyPayment((prev) => ({
            ...prev,
            processing: false,
            success: false,
            message: res.message,
          }));
        }
      });
    } catch (error: any) {
      setStep(3);
      setAlert({
        show: true,
        type: "error",
        message:
          error.message ||
          "Sorry, an error occured while processing, try again",
      });
    }
  };

  return (
    <section className="buy fade-in">
      <Alert
        style={{
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          marginBottom: 15,
          width: "100%",
        }}
        show={alert.show}
        type={alert.type}
        message={alert.message}
      />
      <section className="buy-container">
        <div className="buy-header">
          <h1 className="buy-title">
            {step === 0 ? null : <CounterLabel count={step} />}
            <span>{subtitle}</span>
          </h1>
        </div>
        {fetchingVouchers && step === 0 ? (
          <div className="buy-vouchers-loading">
            <Loader stroke="grey" size={24} />
            <div className="buy-vouchers-loading-text">
              Please wait while we fetch the available vouchers for you
            </div>
          </div>
        ) : (
          <section
            style={{
              display: step === 0 ? "flex" : "none",
            }}
            className="buy-vouchers">
            {vouchers.length > 0 ? (
              vouchers.map((v) => (
                <div className="buy-voucher" key={v.id}>
                  <GiftIcon size={24} />
                  <div className="buy-voucher-title">
                    <span> {v.title}</span>
                  </div>
                  <button
                    className="buy-voucher-btn btn-primary"
                    onClick={() => {
                      setStep(1);
                      setVoucher((prev) => ({
                        ...prev,
                        partner: {
                          id: v.partner_id,
                          name: v.title,
                        },
                      }));
                    }}>
                    <span>Buy</span>
                    <ArrowRightIcon />
                  </button>
                </div>
              ))
            ) : (
              <div className="buy-vouchers-empty">
                Soory, no vouchers available at the moment in this category
              </div>
            )}
          </section>
        )}
        <section
          style={{
            display: step === 1 ? "block" : "none",
          }}
          className="voucher-type fade-in">
          <GroupedRadios
            style={{
              margin: "10px auto",
              flexDirection: "row",
            }}
            items={[
              {
                label: "Open",
                value: "open",
                default: true,
              },
              {
                label: "Closed",
                value: "closed",
              },
            ]}
            onChange={(value: "open" | "closed") => {
              setVoucher((prev) => ({
                ...prev,
                type: value,
              }));
            }}
          />
          <form onSubmit={handleSetVoucher}>
            {voucher.type === "open" ? (
              <>
                <div className="input-field">
                  <label htmlFor="amount" className="input-label">
                    Amount in ksh
                  </label>
                  <input
                    id="amount"
                    name="amount"
                    className="input"
                    type="number"
                    placeholder="Amount in ksh"
                    defaultValue={500}
                    min={500}
                    max={100000}
                    required
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="maturity" className="input-label">
                    Date of maturity
                  </label>
                  <input
                    id="maturity"
                    name="maturity"
                    className="input"
                    type="date"
                    placeholder="Date of maturity"
                    defaultValue={createDate}
                    min={createDate}
                    required
                  />
                </div>
              </>
            ) : (
              <>
                <div className="input-field">
                  <label htmlFor="use" className="input-label">
                    <div>Amount in ksh</div>
                    <div className="input-label-info">
                      Express vouchers do not need sender's approval to redeem
                    </div>
                  </label>
                  <select id="use" name="use" className="input" required>
                    <option value="express">Express</option>
                    <option value="restricted">Restricted</option>
                  </select>
                </div>
                <div className="input-field">
                  <label htmlFor="amount" className="input-label">
                    Amount in ksh
                  </label>
                  <input
                    id="amount"
                    name="amount"
                    className="input"
                    type="number"
                    placeholder="Amount in ksh"
                    defaultValue={500}
                    min={500}
                    max={100000}
                    required
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="recipient" className="input-label">
                    Recipient phone number
                  </label>
                  <input
                    id="recipient"
                    name="recipient"
                    className="input"
                    type="tel"
                    placeholder="Recipient phone number"
                    defaultValue={recepientPhone}
                    onChange={(e) => {
                      const phone = coreService.formatPhoneNumber({
                        phoneNumber: e.target.value,
                        countryCode: "KE",
                      });
                      phone.isValid
                        ? setRecepientPhone(phone.phoneNumber)
                        : setRecepientPhone("");
                    }}
                    required
                  />
                  {recepientPhone === "" ? (
                    <div className="input-error">
                      Please enter valid phone number
                    </div>
                  ) : null}
                </div>
                <div className="input-field">
                  <label htmlFor="maturity" className="input-label">
                    Date of maturity
                  </label>
                  <input
                    id="maturity"
                    name="maturity"
                    className="input"
                    type="date"
                    placeholder="Date of maturity"
                    defaultValue={createDate}
                    min={createDate}
                    required
                  />
                </div>
              </>
            )}
            <div className="form-footer-cta">
              <button
                type="button"
                className="btn btn-secondary btn-voucher-cta"
                onClick={() => {
                  setStep(0);
                }}>
                <ArrowLeftIcon />
                <span>Back</span>
              </button>
              <button className="btn btn-primary btn-voucher-cta">
                <span>Proceed</span>
                <ArrowRightIcon />
              </button>
            </div>
          </form>
        </section>
        <section
          style={{
            display: step === 2 ? "block" : "none",
          }}>
          <form
            className="buy-payment-options fade-in"
            onSubmit={handleSetPayment}>
            {[
              {
                name: "mpesa",
                label: "Mpesa",
                description: "Pay with M-Pesa express checkout",
                default: true,
              },
            ].map((method) => (
              <div
                key={method.name}
                className="buy-payment-option"
                onClick={() => {
                  setPaymentMethod(method);
                }}>
                <div
                  className={`buy-payment-option-box ${
                    paymentMethod.name === method.name || method.default
                      ? "buy-payment-option-box-active"
                      : ""
                  }`}>
                  {paymentMethod.name === method.name || method.default ? (
                    <CheckCircleIcon />
                  ) : (
                    <CircleIcon />
                  )}
                </div>
                <div className="buy-payment-option-label">
                  <div>{method.label}</div>
                  <div className="buy-payment-option-info">
                    {method.description}
                  </div>
                  {(method.name === "mpesa" &&
                    paymentMethod.name === "mpesa") ||
                  method.default ? (
                    <>
                      <input
                        className="input mpesa-input"
                        type="tel"
                        placeholder="e.g 0712345678"
                        defaultValue={currentUser?.phoneNumber || ""}
                        onChange={(e) => {
                          const phone = coreService.formatPhoneNumber({
                            countryCode: "KE",
                            phoneNumber: e.target.value,
                          });
                          phone.isValid
                            ? setMpesaPhone(phone.phoneNumber)
                            : setMpesaPhone("");
                        }}
                      />
                      {mpesaPhone === "" ? (
                        <div className="input-error">
                          Please enter valid phone number
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            ))}
            <div className="form-footer-cta">
              <button
                type="button"
                className="btn btn-secondary btn-voucher-cta"
                onClick={() => setStep(1)}>
                <ArrowLeftIcon />
                <span>Back</span>
              </button>
              <button className="btn btn-primary btn-voucher-cta">
                <span>Next</span>
                <ArrowRightIcon />
              </button>
            </div>
          </form>
        </section>
        <section
          style={{
            display: step === 3 ? "block" : "none",
          }}>
          <div className="buy-pre-order fade-in">
            <div className="buy-pre-order-title">
              Below is the voucher you are about to buy
            </div>
            <div className="buy-pre-order-voucher">
              <div className="buy-pre-order-voucher-left">Partner</div>
              <div className="buy-pre-order-voucher-right">
                {voucher.partner.name}
              </div>
            </div>
            <div className="buy-pre-order-voucher">
              <div className="buy-pre-order-voucher-left">Amount</div>
              <div className="buy-pre-order-voucher-right">{`${
                voucher.currency
              } ${voucher.amount.toLocaleString()}`}</div>
            </div>
            <div className="buy-pre-order-voucher">
              <div className="buy-pre-order-voucher-left">Maturity</div>
              <div className="buy-pre-order-voucher-right">
                {dateService.toString(voucher.maturity.date, "date")}
              </div>
            </div>
            <div className="buy-pre-order-voucher">
              <div className="buy-pre-order-voucher-left">Type</div>
              <div className="buy-pre-order-voucher-right">{voucher.type}</div>
            </div>
            <div className="buy-pre-order-voucher">
              <div className="buy-pre-order-voucher-left">Use</div>
              <div className="buy-pre-order-voucher-right">{voucher.use}</div>
            </div>
            <div className="buy-pre-order-voucher">
              <div className="buy-pre-order-voucher-left">Recepient</div>
              <div className="buy-pre-order-voucher-right">+254713850804</div>
            </div>
          </div>
          <div className="form-footer-cta">
            <button
              type="button"
              className="btn btn-secondary btn-voucher-cta"
              onClick={() => setStep(2)}>
              <ArrowLeftIcon />
              <span>Back</span>
            </button>
            <button
              className="btn btn-primary btn-voucher-cta"
              onClick={handlePayment}>
              <span>Pay</span>
              <ArrowRightIcon />
            </button>
          </div>
        </section>
        <section>
          {step === 5 ? (
            <>
              {verifyPayment.processing ? (
                <div className="buy-payment-verifying fade-in">
                  <div className="buy-payment-verifying-loader">
                    <ClockLoader color="rgb(54, 215, 183)" size={60} />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                    className="buy-payment-verifying-info">
                    Please wait while we verify your payment,
                    <br /> thanks for your patience.
                  </div>
                </div>
              ) : (
                <>
                  {" "}
                  <Alert
                    type={verifyPayment.success ? "success" : "error"}
                    showIcon={false}
                    message={
                      verifyPayment.success ? (
                        <div className="buy-payment-verifying-text success-text">
                          {verifyPayment.message}
                        </div>
                      ) : (
                        <div className="buy-payment-verifying-text error-text">
                          {verifyPayment.message}
                        </div>
                      )
                    }
                  />
                  <div className="buy-payment-cta">
                    {verifyPayment.success ? null : (
                      <button
                        type="button"
                        disabled={verifyPayment.processing}
                        className={
                          verifyPayment.success
                            ? `btn btn-primary btn-voucher-cta`
                            : `btn btn-secondary btn-voucher-cta`
                        }
                        onClick={handlePaymentVerification}>
                        <>
                          <span
                            style={{
                              marginRight: "0.3rem",
                            }}>
                            Retry, I've paid
                          </span>
                          <SyncIcon />
                        </>
                      </button>
                    )}
                    <button
                      type="button"
                      disabled={verifyPayment.processing}
                      className="btn btn-primary btn-voucher-cta"
                      onClick={() => {
                        setStep(0);
                        setVerifyPayment({
                          ...verifyPayment,
                          success: false,
                          message: "",
                        });
                        setAlert({
                          ...alert,
                          show: false,
                          message: "",
                        });
                      }}>
                      {verifyPayment.success ? (
                        <>
                          <span>Continue</span>
                          <ArrowRightIcon />
                        </>
                      ) : (
                        "Exit, I'll try again later"
                      )}
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {step === 4 ? (
                <>
                  {verifyPayment.processing ? (
                    <div
                      style={{ marginBottom: 8 }}
                      className="flex-col-centre">
                      <BarLoader color="rgb(54, 215, 183)" width={75} />
                    </div>
                  ) : null}
                  <Alert
                    type="info"
                    showIcon={false}
                    message={
                      verifyPayment.processing ? (
                        <div>
                          Hey{" "}
                          <b>
                            {currentUser?.displayName?.split(" ")[0] || "there"}
                          </b>
                          , we are processing your M-pesa payment, this may take
                          a few seconds.
                        </div>
                      ) : (
                        verifyPayment.message
                      )
                    }
                  />
                  {verifyPayment.processing ? null : (
                    <div className="buy-payment-cta">
                      <button
                        type="button"
                        className="btn btn-primary btn-voucher-cta"
                        onClick={handlePaymentVerification}>
                        <span>Next</span>
                        <ArrowRightIcon />
                      </button>
                    </div>
                  )}
                </>
              ) : null}
            </>
          )}
        </section>
      </section>
      <div
        style={{
          display: fetchingVouchers || step !== 0 ? "none" : "block",
          marginTop: 20,
        }}
        className="form-footer-cta">
        <button
          type="button"
          className="btn btn-secondary btn-voucher-cta"
          onClick={() => {
            setStep(0);
            setPreview((prev: any) => {
              return {
                ...prev,
                buy: false,
              };
            });
          }}>
          <ArrowLeftIcon />
          <span>Back</span>
        </button>
      </div>
    </section>
  );
};

export default Buy;
