import { BalanceComponent, Sidebar, Summary } from "components";
import { AuthContext } from "contexts";
import { useContext, useEffect } from "react";

const ProfilePage = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    document.title = `Profile - Your Account`;
  }, []);
  return (
    <section className="page-view">
      <main>
        <section className="flex">
          <section className="flex-nav">
            <Sidebar user={currentUser} />
          </section>
          <section className="flex-left">
            <section className="inner-left">
              <div className="profile-header">Maye Edwin</div>
            </section>
          </section>
          <section className="flex-right">
            <section className="flex-container">
              <section className="right-top">
                <div className="top-inner">
                  <BalanceComponent />
                </div>
              </section>
              <Summary title="Purchased" />
            </section>
          </section>
        </section>
      </main>
    </section>
  );
};

export default ProfilePage;
