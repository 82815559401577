import { useEffect, useState, createContext } from "react";
import { useTokenCollection } from "../../hooks";
import { MainContextInterface, TokenResponse } from "../../models";

const MainContext = createContext<MainContextInterface>({
  wallet: {
    balance: 0,
    phoneNumber: undefined,
  },
  loadBalance: true,
  setLoadBalance: () => null,
  sentTokens: [],
  setSentTokens: () => null,
  receivedTokens: [],
  setReceivedTokens: () => null,
  loadingTokens: false,
});

const MainContextProvider = ({ children }: any) => {
  const [wallet, setWallet] = useState<{
    balance: 0;
    phoneNumber: undefined;
  }>({ balance: 0, phoneNumber: undefined });
  const [loadBalance, setLoadBalance] = useState(true);
  const [sentTokens, setSentTokens] = useState<TokenResponse[]>([]);
  const [receivedTokens, setReceivedTokens] = useState<TokenResponse[]>([]);
  const [data, loadingTokens] = useTokenCollection();
  // Effect to get the list of tokens....
  useEffect(() => {
    if (data) {
      setSentTokens(data.sent);
      setReceivedTokens(data.received);
    } else {
      setSentTokens([]);
      setReceivedTokens([]);
    }
    return () => {
      setSentTokens([]);
      setReceivedTokens([]);
    };
  }, [data, setReceivedTokens, setSentTokens]);

  return (
    <MainContext.Provider
      value={{
        wallet,
        setWallet,
        loadBalance,
        setLoadBalance,
        sentTokens,
        setSentTokens,
        receivedTokens,
        setReceivedTokens,
        loadingTokens,
      }}>
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
