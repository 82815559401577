import "./placeholder.css";

const TokenPlaceholder = () => {
  return (
    <section className="token-placeholder-container">
      <div className="token-placeholder-item">
        <div
          style={{ width: "80%" }}
          className="on-loading token-placeholder-text"></div>
        <div
          style={{ width: "60%" }}
          className="on-loading token-placeholder-text"></div>
        <div>
          <span className="on-loading placeholder-btn"></span>
          <span
            style={{ marginLeft: 5 }}
            className="on-loading placeholder-btn"></span>
        </div>
      </div>

      <div className="token-placeholder-item">
        <div
          style={{ width: "80%" }}
          className="on-loading token-placeholder-text"></div>
        <div
          style={{ width: "60%" }}
          className="on-loading token-placeholder-text"></div>
        <div>
          <span className="on-loading placeholder-btn"></span>
          <span
            style={{ marginLeft: 5 }}
            className="on-loading placeholder-btn"></span>
        </div>
      </div>
    </section>
  );
};

const CategoryPlaceholder = () => {
  return (
    <section className="category-placeholder-container">
      <div>
        <div className="category-placeholder-item"></div>
        <div
          style={{ marginTop: 12, height: 15 }}
          className="on-loading token-placeholder-text"></div>
      </div>
      <div>
        <div className="category-placeholder-item"></div>
        <div
          style={{ marginTop: 12, height: 15 }}
          className="on-loading token-placeholder-text"></div>
      </div>
      <div>
        <div className="category-placeholder-item"></div>
        <div
          style={{ marginTop: 12, height: 15 }}
          className="on-loading token-placeholder-text"></div>
      </div>
      <div>
        <div className="category-placeholder-item"></div>
        <div
          style={{ marginTop: 12, height: 15 }}
          className="on-loading token-placeholder-text"></div>
      </div>
    </section>
  );
};

const InboxPlaceholder = () => {
  return <section className="placeholder-box-container on-loading"></section>;
};

export { CategoryPlaceholder, TokenPlaceholder, InboxPlaceholder };
