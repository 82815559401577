import {
  ArrowLeftIcon,
  CheckIcon,
  CopyIcon,
  XIcon,
} from "@primer/octicons-react";
import axios from "axios";
import { Loader } from "components";
import { AuthContext } from "contexts";
import { pwa } from "pwafire";
import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import "./purchase.css";

// Interfaces...
interface PaymentResponse {
  code: string;
  data: any;
  message: string;
  ok: boolean;
}

const Purchase = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { idToken, hostAPI } = useContext(AuthContext);
  const token = localStorage.getItem("v-token");
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<PaymentResponse>({
    code: "",
    data: null,
    message: "",
    ok: false,
  });

  useEffect(() => {
    document.title = "Purchase - Confirmation";
  }, []);

  useEffect(() => {
    if (hostAPI && idToken) {
      (async () => {
        try {
          const urlParams = new URLSearchParams(location.search);
          const status = urlParams.get("status");
          const tx_ref = urlParams.get("tx_ref");
          const transaction_id = urlParams.get("transaction_id");
          const token = localStorage.getItem("v-token");
          const headers = {
            Authorization: `Bearer ${idToken}`,
          };
          const data = {
            first_step: false,
            status: status,
            transaction_id: transaction_id,
            transaction_ref: tx_ref,
            token: token,
          };
          localStorage.setItem("v-data", JSON.stringify(data));
          const res = await axios.post(`${hostAPI}/buy`, data, {
            headers: headers,
          });
          if (res) {
            setRes({
              ...res.data,
              ok: res.data.code === "buy/verified" ? true : false,
            });
            setLoading(false);
          } else {
            setLoading(true);
          }
        } catch (error) {
          setRes((prev) => {
            return {
              ...prev,
              ok: false,
              message: "Payment validation failed, please try again.",
            };
          });
        }
      })();
    }
    return () => {
      setRes({
        code: "",
        data: null,
        message: "",
        ok: false,
      });
      setLoading(true);
    };
  }, [location, hostAPI, idToken]);

  const handleRetry = (event: any) => {
    event.preventDefault();
    setLoading(true);
    const payload = localStorage.getItem("v-data");
    if (payload) {
      const data = JSON.parse(payload);
      axios
        .post(`${hostAPI}/buy`, data, {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        })
        .then((res) => {
          if (res) {
            setRes(res.data);
            setLoading(false);
          }
        })
        .catch(() => {
          setRes((prev) => {
            return {
              ...prev,
              ok: false,
              message: "Payment validation failed!",
            };
          });
        });
    } else {
      setRes((prev) => {
        return {
          ...prev,
          ok: false,
          message: "Payment validation failed!",
        };
      });
    }
  };

  const handleCopyVoucher = async (event: any) => {
    event.preventDefault();
    try {
      if (token) {
        const res = await pwa.copyText(token);
        setCopied(res.ok);
        setTimeout(() => {
          setCopied(false);
          navigate("/");
        }, 1000);
      } else {
        setCopied(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="page purchase-page">
      <section className="purchase-header-back">
        <NavLink className="purchase-header-back-icon" to="/">
          <ArrowLeftIcon size={24} />{" "}
          <span style={{ marginLeft: 5 }}>Back</span>
        </NavLink>
      </section>
      <section className="purchase-content">
        <div className="purchase-title">Payment Confirmation</div>
        {loading ? (
          <div className="purchase-loading">
            <Loader size={48} strokeWidth={2.75} />
          </div>
        ) : (
          <section>
            <div
              className={
                res.ok
                  ? "purchase-content-icon icon-success"
                  : "purchase-content-icon icon-error"
              }>
              {res.ok ? <CheckIcon size={48} /> : <XIcon size={48} />}
            </div>
            <h1 className="purchase-content-title">
              {res.ok
                ? "Success"
                : res.code === "buy/cancelled"
                ? "Cancelled"
                : "Sorry"}
            </h1>
            <section className="purchase-content-subtitle">
              <div className="purchase-message">{res.message}</div>
            </section>
            <section>
              {res.ok ? (
                <div className="purchase-content-voucher">
                  <div
                    onClick={handleCopyVoucher}
                    className="purchase-content-voucher-text">
                    Token :{" "}
                    <span className="purchase-content-voucher-code">
                      {token}
                    </span>{" "}
                    <span>
                      {copied ? (
                        <CheckIcon fill="green" size={18} />
                      ) : (
                        <CopyIcon size={18} />
                      )}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="purchase-content-alt">
                  <Link className="purchase-content-voucher-text" to="/">
                    Go Back
                  </Link>
                  <button
                    className="btn purchase-retry-button"
                    onClick={handleRetry}>
                    Try Again
                  </button>
                </div>
              )}
            </section>
          </section>
        )}
      </section>
    </section>
  );
};

export default Purchase;
