import { ChevronRightIcon, SignOutIcon } from "@primer/octicons-react";
import { BalanceComponent, BottomNavbar, Sidebar, Summary } from "components";
import { AuthContext } from "contexts";
import { fb } from "modules";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "./account.css";

const AccountPage = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    document.title = `Settings - Your Account`;
  }, []);

  return (
    <section className="page-view ">
      <main>
        <section className="flex">
          <section className="flex-nav">
            <Sidebar user={currentUser} />
          </section>
          <section className="flex-left">
            <div className="inner-left">
              <div className="left-header sticky-header">
                <h1 className="left-h1">Account</h1>
              </div>
              <section className="wallet fade-in">
                <Link to="/account/profile" className="wallet-nav-item">
                  <span className="wallet-nav-item-left">Profile</span>{" "}
                  <span className="wallet-nav-item-right">
                    <ChevronRightIcon size={24} />
                  </span>
                </Link>
                <Link
                  to="/account"
                  onClick={(event) => {
                    event.preventDefault();
                    fb.auth.signOut();
                  }}
                  className="wallet-nav-item">
                  <span className="wallet-nav-item-left">Sign out</span>
                  <span
                    style={{ paddingRight: 5 }}
                    className="wallet-nav-item-right">
                    <SignOutIcon />
                  </span>
                </Link>
              </section>
            </div>
          </section>
          <section className="flex-right">
            <section className="flex-container">
              <section className="right-top">
                <div className="top-inner">
                  <BalanceComponent />
                </div>
              </section>
              <Summary title="Purchased" />
            </section>
          </section>
        </section>
      </main>
      <BottomNavbar />
    </section>
  );
};

export default AccountPage;
