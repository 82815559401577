import { initializeApp } from "firebase/app";
import {
  RecaptchaVerifier,
  User,
  applyActionCode,
  checkActionCode,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPhoneNumber,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const perf = getPerformance(app);
const authUser = auth.currentUser;

export {
  RecaptchaVerifier,
  applyActionCode,
  auth,
  authUser,
  checkActionCode,
  collection,
  db,
  doc,
  getAuth,
  getDoc,
  getDocs,
  limit,
  onAuthStateChanged,
  onSnapshot,
  orderBy,
  perf,
  query,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPhoneNumber,
  updateDoc,
  updateEmail,
  updateProfile,
  where,
};

export type { User };
