import { ChevronRightIcon } from "@primer/octicons-react";
import "assets/styles/wallet.css";
import { BalanceComponent, BottomNavbar, Sidebar, Summary } from "components";
import { AuthContext } from "contexts";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

const TransactionsPage = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser)
      document.title = `Activity ${currentUser.displayName || ""}`;
  }, [currentUser]);

  return (
    <section className="page-view">
      <section className="flex">
        <section className="flex-nav">
          <Sidebar user={currentUser} />
        </section>
        <section className="flex-left">
          <div className="inner-left">
            <div className="left-header">
              <div className="left-h1">Activity</div>
            </div>
            <section className="wallet fade-in">
              <Link to="/transactions/requests" className="wallet-nav-item">
                <span className="wallet-nav-item-left">Requests</span>{" "}
                <span className="wallet-nav-item-right">
                  <ChevronRightIcon size={24} />
                </span>
              </Link>
              <Link to="/transactions/purchases" className="wallet-nav-item">
                <span className="wallet-nav-item-left">Purchases</span>
                <span className="wallet-nav-item-right">
                  <ChevronRightIcon size={24} />
                </span>
              </Link>
            </section>
          </div>
        </section>
        <section className="flex-right">
          <section className="flex-container">
            <section className="right-top">
              <div className="top-inner">
                <BalanceComponent />
              </div>
            </section>
            <Summary title="Purchased" />
          </section>
        </section>
      </section>
      <BottomNavbar />
    </section>
  );
};

export default TransactionsPage;
