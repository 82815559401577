import styled from "styled-components";
const Counter = ({
  className,
  count,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
  count?: number;
  color?: string;
}) => <div className={className}>{children || count || 0}</div>;

export const CounterLabel = styled(Counter)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ color = "#259c8c" }) => color};
  color: #fff;
  font-size: 13px;
  font-weight: 700;
`;
