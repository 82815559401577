import { useContext, useCallback, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon } from "@primer/octicons-react";
import { AuthContext } from "../../../contexts/auth";
import Sidebar from "../../../components/sidebar";
import BalanceComponent from "../../../components/balance";
import { Alert, Summary } from "../../../components";
import { fb } from "../../../modules";

const NamePage = () => {
  const { currentUser } = useContext(AuthContext);
  const displayName = currentUser?.displayName;
  const [alert, setAlert] = useState<{
    message: string;
    show: boolean;
    type: "success" | "error";
  }>({
    message: "Default message",
    show: false,
    type: "success",
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    document.title = `Settings - Update Name`;
  }, []);

  const handleSubmit = useCallback(async (event: any) => {
    try {
      event.preventDefault();
      const { name } = event.target.elements;
      setSaving(true);
      if (name.value.length > 5 && fb.auth.currentUser) {
        await fb.updateProfile(fb.auth.currentUser, {
          displayName: name.value,
        });
        setAlert({
          type: "success",
          message: `Successfully updated your name`,
          show: true,
        });
        setSaving(false);
      } else {
        setAlert({
          show: true,
          message: "Sorry, your name must be at least 6 characters long",
          type: "error",
        });
        setSaving(false);
      }
    } catch (error: any) {
      setAlert({
        type: "error",
        message: error.message || "An error occurred",
        show: true,
      });
      setSaving(false);
    }
  }, []);

  return (
    <div className="update-account">
      <main>
        <section className="flex">
          <section className="flex-nav">
            <Sidebar user={currentUser} />
          </section>
          <section className="flex-left">
            <div className="inner-left">
              <div className="left-header">
                <NavLink className="account-page-flexbox" to="/account/profile">
                  <ArrowLeftIcon size={24} />
                  <div className="account-page-back">
                    {" "}
                    <div className="left-h1">Update name</div>
                  </div>
                </NavLink>
              </div>
              <div className="account">
                <form className="account-page-form" onSubmit={handleSubmit}>
                  <Alert
                    message={alert.message}
                    show={alert.show}
                    onClose={() => setAlert({ ...alert, show: false })}
                    type={alert.type}
                  />
                  <div className="account-page-container">
                    <input
                      className="account-input"
                      type="text"
                      placeholder={
                        displayName ? displayName : "Enter full legal name"
                      }
                      name="name"
                      autoComplete="off"
                      required={true}
                    />
                    <button
                      disabled={saving}
                      className="account-btn"
                      type="submit">
                      {saving ? "Saving" : " Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className="flex-right">
            <section className="flex-container">
              <section className="right-top">
                <div className="top-inner">
                  <BalanceComponent />
                </div>
              </section>
              <Summary title="Purchased" />
            </section>
          </section>
        </section>
      </main>
    </div>
  );
};

export default NamePage;
