import "./loader.css";

const LineLoader = ({
  show = true,
  size = "2px",
  color = "#00bcd4",
  speed = 1,
}) => {
  return show ? (
    <section className="loader-overlay">
      <div className="loader-line">
        <div className="loader-line-container"></div>
      </div>
    </section>
  ) : null;
};

export { LineLoader };
