import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import { KebabHorizontalIcon } from "@primer/octicons-react";
import "../../assets/styles/header.css";
import Avatar from "../../assets/images/Avatar.png";

const Header = (props) => {
  const { currentUser } = useContext(AuthContext);
  const { displayName, photoURL } = currentUser;
  const [modal, setModal] = useState(true);
  const [navbar, setNavbar] = useState(true);
  const handleClick = (event) => {
    event.preventDefault();
    const more = document.getElementById("modal");
    setModal(!modal);
    if (modal) {
      more.style.display = "block";
    } else {
      more.style.display = "none";
    }
  };

  const handleNavbar = () => {
    const left_navbar = document.getElementById("left-navbar");
    setNavbar(!navbar);
    if (navbar) {
      left_navbar.style.display = "flex";
      props.set({ opacity: 0.2, backgroundColor: "rgb(255, 255, 255)" });
    } else {
      left_navbar.style.display = "none";
    }
  };

  return (
    <header className="header" style={props.style}>
      <nav className="top-nav">
        <div className="nav-left" onClick={handleNavbar}>
          <img
            className="avatar"
            src={photoURL ? photoURL : Avatar}
            alt={displayName ? displayName.charAt(0) : "Avatar"}
            title={displayName ? displayName : "Avatar"}
            onError={(e) => {
              e.target.src = Avatar;
            }}
          />
        </div>
        <div className="nav-right">
          <div className="nav-show" onClick={handleClick}>
            <KebabHorizontalIcon size={24} />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
