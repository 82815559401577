import { useEffect, useState } from "react";

// Interface for props...
interface Props {
  photoURL?: string;
  displayName: string;
}

const Avatar = ({ photoURL, displayName }: Props) => {
  const [label, setLabel] = useState("DD");
  useEffect(() => {
    if (displayName) {
      const nameSymbols = displayName.split(" ");
      const firstSymbol = nameSymbols[0][0];
      const lastSymbol = nameSymbols[nameSymbols.length - 1][0];
      setLabel(
        `${firstSymbol ? firstSymbol : "P"}${lastSymbol ? lastSymbol : ""}`
      );
    }
  }, [displayName]);

  return photoURL ? (
    <img
      className="side-avatar fadein"
      src={photoURL}
      alt={displayName ? displayName : "User avatar"}
      title={displayName ? displayName : "User avatar"}
      onError={(e: any) => {
        e.target.src = "/assets/images/Avatar.png";
      }}
    />
  ) : (
    <div className="side-avatar fadein">
      <span>{label}</span>
    </div>
  );
};

export default Avatar;
