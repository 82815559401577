import music from "assets/images/home/music.svg";

const BoxCard = ({ category, setPreview }: any) => {
  return (
    <div
      onClick={() => {
        setPreview((prev: any) => {
          return {
            ...prev,
            buy: true,
            category,
          };
        });
      }}
      className="item item--1 categories fade-in"
      key={category.id}>
      <div className="category-card">
        <div className="card-header">
          <img
            loading="lazy"
            src={category.image}
            className="category-logo"
            alt={category.name ? category.name : "logo"}
            onError={(e: any) => {
              e.target.src = music;
            }}
          />
        </div>
        <div className="card-content">{category.name}</div>
      </div>
    </div>
  );
};

export default BoxCard;
