import { ChevronLeftIcon, ChevronRightIcon } from "@primer/octicons-react";
import { useContext, useEffect, useState } from "react";
import { ActionButton, Message } from "..";
import { AuthContext } from "../../contexts";
import { PayoutRequest } from "../../models";
import { apiService } from "../../services";
import { InboxPlaceholder } from "../commons/placholders";
import "./inbox.css";

const Inbox = () => {
  // States...
  const { idToken, hostAPI, currentUser } = useContext(AuthContext);
  const [messages, setMessages] = useState<PayoutRequest[]>([]);
  const [message, setMessage] = useState<PayoutRequest>({
    amount: 0,
    date: {
      created_at: "",
      created_on: "",
      time_since: "",
    },
    payee: "",
    status: "",
    token: "",
    ref: "",
  });
  const [indexActiveMessage, setIndexActiveMessage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [payProgress, setPayProgress] = useState(false);
  const [rejectProgress, setRejectProgress] = useState(false);
  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    success: false,
  });

  // Handle alert message...
  const handleAlert = (message: string, success: boolean) => {
    setAlert({
      isOpen: true,
      message: message,
      success: success,
    });
  };

  // Fetch messages from API...
  useEffect(() => {
    if (currentUser && currentUser.phoneNumber) {
      apiService.getPaymentRequests(currentUser.phoneNumber, (data) => {
        // Set messages...
        setMessages(data);
        // Set loading...
        setIsLoading(false);
      });
    } else {
      // Set loading...
      setIsLoading(false);
      // Set messages...
      setMessages([]);
    }
    // Clean up...
    return () => {
      setIsLoading(false);
      setMessages([]);
    };
  }, [currentUser]);

  // Handle message...
  useEffect(() => {
    if (messages && messages.length > 0) {
      const found = messages[indexActiveMessage] as PayoutRequest;
      setMessage(found);
    }
  }, [messages, indexActiveMessage]);

  // Get next message...
  const nextMessage = (e: { preventDefault: () => any }) => {
    e.preventDefault();
    if (indexActiveMessage < messages.length - 1) {
      setIndexActiveMessage(indexActiveMessage + 1);
    } else {
      setIndexActiveMessage(0);
    }
  };

  // Get previous message...
  const previousMessage = (e: { preventDefault: () => any }) => {
    e.preventDefault();
    if (indexActiveMessage > 0 && indexActiveMessage < messages.length) {
      setIndexActiveMessage(indexActiveMessage - 1);
    } else {
      setIndexActiveMessage(messages.length - 1);
    }
  };

  const handlePayRequest = async (approve: boolean) => {
    try {
      approve ? setPayProgress(true) : setRejectProgress(true);
      const res: any = await apiService.getData({
        url: `${hostAPI}/redeem/approve`,
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: {
          approve: approve,
          ref: message.ref,
        },
      });
      if (res.ok) {
        // Set message to approved...
        handleAlert(res.message, res.code === "redeem/success" ? true : false);
        setPayProgress(false);
        setRejectProgress(false);
        // Remove message from list...
        const newMessages = messages.filter(
          (message: PayoutRequest) => message.ref !== res.data.ref
        );
        setMessages(newMessages);
        setIndexActiveMessage(0);
      } else {
        handleAlert(res.message, false);
        setPayProgress(false);
        setRejectProgress(false);
        setIndexActiveMessage(0);
      }
    } catch (error) {
      throw error;
    }
  };

  return isLoading ? (
    <InboxPlaceholder />
  ) : (
    <div className="inbox-container">
      <div className="inbox-row">
        <span className="inbox-title">Inbox</span>
        {messages.length === 0 || messages.length === 1 ? null : (
          <div className="inbox-buttons">
            <button
              disabled={alert.isOpen || messages.length === 0 ? true : false}
              onClick={previousMessage}>
              <ChevronLeftIcon />
            </button>
            <button
              disabled={alert.isOpen || messages.length === 0 ? true : false}
              onClick={nextMessage}>
              <ChevronRightIcon />
            </button>
          </div>
        )}
      </div>
      {alert.isOpen ? (
        <div
          className={`alert-text ${
            alert.success ? "alert-text-success" : "alert-text-error "
          }`}>
          {alert.message}
        </div>
      ) : (
        <Message
          current={indexActiveMessage + 1}
          count={messages.length}
          message={message}
          loading={isLoading}
        />
      )}
      <div className="action-buttons">
        {alert.isOpen ? (
          <ActionButton
            className="ok-button"
            label="Ok"
            type="button"
            disabled={isLoading}
            onClick={async (event) => {
              event.preventDefault();
              setAlert({
                success: false,
                isOpen: false,
                message: "",
              });
            }}
          />
        ) : (
          <div
            className="btns"
            style={
              messages.length > 0 && !isLoading ? {} : { display: "none" }
            }>
            <ActionButton
              className="btn btn-primary"
              label="Approve"
              type="button"
              inProgress={payProgress}
              disabled={
                isLoading || (messages.length === 0 && alert.isOpen === false)
              }
              onClick={async (event) => {
                event.preventDefault();
                try {
                  await handlePayRequest(true);
                } catch (error) {
                  if (error instanceof Error) {
                    setPayProgress(false);
                    setRejectProgress(false);
                    handleAlert(error.message, false);
                  }
                }
              }}
            />
            <ActionButton
              className="btn btn-secondary"
              label="Reject"
              type="button"
              disabled={
                isLoading || (messages.length === 0 && alert.isOpen === false)
              }
              onClick={async (event) => {
                event.preventDefault();
                try {
                  await handlePayRequest(false);
                } catch (error) {
                  if (error instanceof Error) {
                    handleAlert(error.message, false);
                  }
                }
              }}
              inProgress={rejectProgress}
            />
          </div>
        )}
      </div>
      <div
        style={{
          // color: "#259c8c",
          textAlign: "right",
          marginTop: 10,
          display: messages.length > 0 && !isLoading ? "block" : "none",
        }}>
        <small>#{message?.token}</small>
      </div>
    </div>
  );
};

export default Inbox;
