import { VerifiedIcon } from "@primer/octicons-react";
import hospitality from "assets/images/home/breakfast.svg";
import retail from "assets/images/home/retail.svg";
import services from "assets/images/home/services.svg";
import travel from "assets/images/home/travel.svg";
import {
  BalanceComponent,
  BottomNavbar,
  CategoryComponent,
  CategoryPlaceholder,
  Inbox,
  PurchaseList,
  Sidebar,
  Slider,
  Summary,
  Header as TopHeader,
} from "components";
import { AuthContext } from "contexts";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { apiService } from "services";
import Buy from "views/buy";
import "./home.css";

const HomePage = () => {
  const { currentUser, idToken, hostAPI } = useContext(AuthContext);

  const [opacity, setOpacity] = useState({ opacity: 1 });
  const [categories, setCategories] = useState<{ [key: string]: string }[]>([]);

  const [preview, setPreview] = useState({
    category: {
      name: "Retail",
    },
    voucher: {
      name: "",
    },
    buy: false,
  });

  const [title] = useState("Purchased");
  const [loadingCategories, setLoadingCategories] = useState(true);

  useEffect(() => {
    if (currentUser) {
      document.title = `Home - ${
        currentUser?.displayName || currentUser.phoneNumber
      }`;
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await apiService.getData({
          url: `${hostAPI}/categories`,
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        });
        if (res.ok) {
          const refined: { [key: string]: string }[] = [];
          res.data.forEach((category: { name: string }) => {
            const name = category.name.toLocaleLowerCase();
            refined.push({
              ...category,
              image:
                name === "retail"
                  ? retail
                  : name === "travel"
                  ? travel
                  : name === "hospitality"
                  ? hospitality
                  : services,
            });
          });
          setCategories(refined);
        } else {
          setCategories([]);
        }
        setTimeout(() => {
          setLoadingCategories(false);
        }, 2000);
      } catch (error) {
        setLoadingCategories(false);
      }
    };
    if (idToken) fetchCategories();
    return () => {
      setCategories([]);
    };
  }, [idToken, hostAPI]);

  if (currentUser) {
    if (!currentUser?.displayName) {
      return <Navigate to="/account/profile/name" />;
    }
    if (currentUser?.displayName && !currentUser.email) {
      return <Navigate to="/account/profile/email" />;
    }
  }

  return (
    <section>
      <section className="home-section" style={opacity}>
        <TopHeader set={setOpacity} />
        <section className="flex">
          <section className="flex-nav">
            <Sidebar user={currentUser} />
          </section>
          <section className="flex-left">
            <section className="inner-left inner-left-home">
              <div className="no-border left-header left-header-home">
                <h1 className="left-h1">Home</h1>
                <div className="label-container-top fadein">
                  <span className="mobile-user-id">
                    <span style={{ color: "#259c8c" }}>
                      <VerifiedIcon />
                    </span>{" "}
                    <span style={{ marginLeft: 5 }}>
                      {currentUser?.phoneNumber || "Loading..."}
                    </span>
                  </span>
                </div>
              </div>
              <section className="home-page-activity fade-in">
                <Inbox />
                {preview.buy ? (
                  <Buy preview={preview} setPreview={setPreview} />
                ) : (
                  <>
                    <section className="home-catesgories-container">
                      <div className="subtitle">All Categories</div>
                      <section className="category-cards-container">
                        {loadingCategories ? (
                          <CategoryPlaceholder />
                        ) : (
                          <CategoryComponent
                            categories={categories}
                            setPreview={setPreview}
                          />
                        )}
                      </section>
                    </section>
                    <Slider
                      style={{
                        display: "none",
                        marginBottom: 35,
                      }}
                    />
                  </>
                )}
              </section>
            </section>
          </section>
          <section className="flex-right">
            <section className="flex-container">
              <section className="right-top">
                <div className="top-inner">
                  <BalanceComponent />
                </div>
              </section>
              <Summary title="Purchased" />
              <Summary
                title="Received"
                style={{ borderTop: "1px solid #e1e4e8" }}
              />
            </section>
            <section className="flex-footer">
              <a className="flex-footer-item" href="/">
                <span>Terms</span>
              </a>
              <a className="flex-footer-item m-left" href="/">
                <span>Privacy</span>
              </a>
              <span className="flex-footer-item m-left">
                <span>© 2020 Pigo Payments.</span>
              </span>
            </section>
          </section>
        </section>
      </section>
      <BottomNavbar style={opacity} />
      <PurchaseList title={title} />
    </section>
  );
};

export default HomePage;
