import { ArrowLeftIcon } from "@primer/octicons-react";
import { Alert, BalanceComponent, Sidebar, Summary } from "components";
import { AuthContext } from "contexts";
import { fb } from "modules";
import { useCallback, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const EmailPage = () => {
  const { currentUser } = useContext(AuthContext);
  const [alert, setAlert] = useState<{
    message: string;
    show: boolean;
    type: "success" | "error";
  }>({
    message: "Default message",
    show: false,
    type: "success",
  });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    document.title = "Settings - Update Email";
  }, []);

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      const { email } = event.target.elements;
      setSaving(true);
      try {
        if (currentUser && fb.auth && fb.auth.currentUser) {
          await fb.updateEmail(fb.auth.currentUser, email.value);
          setAlert({
            message: `Acccount email updated`,
            show: false,
            type: "success",
          });
          setSaving(false);
        } else {
          setAlert({
            message: `Sorry, we could not update your email`,
            show: false,
            type: "error",
          });
          setSaving(false);
        }
      } catch (error: any) {
        if (error && error.code === "auth/requires-recent-login") {
          setAlert({
            message: "Please re-authenticate your account to update your email",
            show: true,
            type: "error",
          });
          setSaving(false);
        } else {
          setAlert({
            message:
              error && error.message
                ? error.message
                : "Sorry, failed to update your email, please try again later",
            show: true,
            type: "error",
          });
          setSaving(false);
        }
      }
    },
    [currentUser]
  );

  return (
    <div className="update-account">
      <main>
        <section className="flex">
          <section className="flex-nav">
            <Sidebar user={currentUser} />
          </section>
          <section className="flex-left">
            <div className="inner-left">
              <div className="left-header">
                <NavLink className="account-page-flexbox" to="/account/profile">
                  <ArrowLeftIcon size={24} />
                  <div className="account-page-back">
                    {" "}
                    <div className="left-h1">Update email</div>
                  </div>
                </NavLink>
              </div>
              <div className="account">
                <form className="account-page-form" onSubmit={handleSubmit}>
                  <Alert
                    show={alert.show}
                    message={alert.message}
                    type={alert.type}
                    onClose={() => setAlert({ ...alert, show: false })}
                  />
                  <div className="account-page-container">
                    <input
                      className="account-input"
                      type="email"
                      placeholder={
                        currentUser?.email || "Enter valid email address"
                      }
                      name="email"
                      autoComplete="off"
                      required={true}
                    />
                    <button
                      disabled={saving}
                      className="account-btn"
                      type="submit">
                      {saving ? "Saving" : " Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className="flex-right">
            <section className="flex-container">
              <section className="right-top">
                <div className="top-inner">
                  <BalanceComponent />
                </div>
              </section>
              <Summary title="Purchased" />
            </section>
          </section>
        </section>
      </main>
    </div>
  );
};

export default EmailPage;
