import { User } from "firebase/auth";
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import { fb } from "modules";

class CoreService {
  formatPhoneNumber(data: { phoneNumber: string; countryCode: CountryCode }) {
    const isValid = isValidPhoneNumber(data.phoneNumber, data.countryCode);
    if (isValid) {
      const phoneNumber = parsePhoneNumber(
        data.phoneNumber || "",
        data.countryCode
      );
      return phoneNumber
        ? {
            phoneNumber: phoneNumber.formatInternational().replace(/\s/g, ""),
            countryCode: phoneNumber.countryCallingCode,
            isValid: phoneNumber.isValid(),
            national: phoneNumber.formatNational().replace(/\s/g, ""),
          }
        : { phoneNumber: "", countryCode: "", isValid: false, national: "" };
    } else {
      return { phoneNumber: "", countryCode: "", isValid: false, national: "" };
    }
  }
}

const coreService = new CoreService();

class AuthService {
  async sendOTP(
    phoneNumber: string,
    callback: (data: { ok: boolean; message: string }) => void
  ) {
    try {
      const container = document.getElementById("recaptcha-container");
      if (container) container.innerHTML = `<div id="recaptcha"></div>`;
      window.recaptchaVerifier = new fb.RecaptchaVerifier(
        fb.auth,
        "recaptcha",
        {
          size: "invisible",
          callback: (response: any) => {
            if (response) {
              callback({
                ok: true,
                message: `Enter the OTP code sent to phone number ending with ${phoneNumber.slice(
                  -4
                )}`,
              });
            }
          },
          "expired-callback": () => {
            callback({
              ok: false,
              message: "OTP code has expired. Please try again.",
            });
          },
        },
      );
      const appVerifier = window.recaptchaVerifier;
      fb.signInWithPhoneNumber(fb.auth, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          window.recaptchaVerifier.clear();
        })
        .catch((error) => {
          if (error.code === "auth/network-request-failed") {
            callback({
              ok: false,
              message: "Network error. Please check your internet connection.",
            });
          }
          if (error.code === "auth/internal-error") {
            callback({
              ok: false,
              message: "Internal error. Please try again.",
            });
          }
          if (error.code === "auth/too-many-requests") {
            callback({
              ok: false,
              message: "Too many requests. Please try again later.",
            });
          }
        });
    } catch (error) {
      callback({
        ok: false,
        message: "Internal error. Please try again.",
      });
    }
  }

  /**
   * This method verifies an otp code
   * @method verifyOTP
   */
  verifyOTP(
    code: string,
    callback: (data: {
      ok: boolean;
      message: string;
      user: User | null;
    }) => void
  ) {
    window.confirmationResult
      .confirm(code)
      .then((result: any) => {
        if (result.user) {
          callback({
            ok: true,
            message: "OTP code verified successfully.",
            user: result.user,
          });
        } else {
          callback({
            ok: false,
            message:
              "Sorry, we couldn't verify your OTP code. Please try again",
            user: null,
          });
        }
      })
      .catch((error: any) => {
        if (error.code === "auth/invalid-verification-code") {
          callback({
            ok: false,
            message: "Invalid OTP code. Please try again.",
            user: null,
          });
        } else {
          callback({
            ok: false,
            message:
              "Sorry, we couldn't verify your OTP code. Please try again",
            user: null,
          });
        }
      });
  }

  /**
   * This method clears the recaptcha verifier
   * @method clearRecaptchaVerifier
   */
  clearRecaptchaVerifier() {
    const recaptchaContainer = document.getElementById("recaptcha-container");
    if (recaptchaContainer) recaptchaContainer.innerHTML = "";
  }
}

const authService = new AuthService();

export { authService, coreService };
