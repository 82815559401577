import { XCircleIcon } from "@primer/octicons-react";
import { LineLoader } from "components";
import { AuthContext } from "contexts";
import { fb } from "modules";
import { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { apiService, authService, coreService } from "services";
import "../auth.css";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

export interface IAuthAlert {
  message: string;
  type: "warn" | "error" | "info" | "success";
  show?: boolean;
}

export const InvalidInput = ({ message }: { message: string }) => (
  <div className="invalid-input-label">{message}</div>
);

export const AuthAlert = ({ message, type, show }: IAuthAlert) => {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setHide(!show);
  }, [show]);

  return hide ? null : (
    <div className={`auth-alert auth-alert-${type}`}>
      <div>{message}</div>
      <span className="auth-alert-icon" onClick={() => setHide(true)}>
        <XCircleIcon />
      </span>
    </div>
  );
};

const Signin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useContext(AuthContext);
  const [processing, setProcessing] = useState(false);
  const [step, setStep] = useState(1);
  const [validInput, setValidInput] = useState({
    phone: true,
    code: true,
  });
  const [otpMessage, setOtpMessage] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    document.title = `Vouchpal - Sign in to your account`;
    fb.auth.useDeviceLanguage();
    if (location.state && location.state.phone) {
      setPhone(location.state.phone);
    }
  }, [location]);

  const handlePhoneChange = (event: any) => {
    event.preventDefault();
    const { value } = event.target;
    if (value.length > 0) {
      const phone = coreService.formatPhoneNumber({
        phoneNumber: value,
        countryCode: "KE",
      });
      if (phone.isValid) {
        setProcessing(true);
        apiService.checkPhoneNumber(phone.phoneNumber, (exists) => {
          if (!exists) {
            navigate("/signup", { state: { phone: phone.phoneNumber } });
          }
          setProcessing(false);
        });
        setValidInput((prev) => ({ ...prev, phone: true }));
      } else {
        setValidInput((prev) => ({ ...prev, phone: false }));
      }
    } else {
      setValidInput((prev) => ({ ...prev, phone: false }));
    }
  };

  const handleGetOTP = (event: any) => {
    event.preventDefault();
    const { tel } = event.target.elements;
    setProcessing(true);
    const phone = coreService.formatPhoneNumber({
      phoneNumber: tel.value,
      countryCode: "KE",
    });
    if (phone.isValid) {
      authService.sendOTP(phone.phoneNumber, (res) => {
        setOtpMessage(res.message);
        setStep(res.ok ? 2 : 1);
        if (res.ok) event.target.reset();
        setProcessing(false);
      });
    } else {
      setProcessing(false);
    }
  };

  const handleVerifyOTP = (event: any) => {
    event.preventDefault();
    const { otp } = event.target.elements;
    setProcessing(true);
    const code = otp.value;
    authService.verifyOTP(code, (res) => {
      if (res.ok) {
        navigate("/");
      } else {
        setProcessing(false);
        authService.clearRecaptchaVerifier();
        setOtpMessage(res.message);
        setValidInput((prev) => ({ ...prev, code: false }));
      }
    });
  };

  return currentUser ? (
    <Navigate to="/" />
  ) : (
    <section className="auth-page">
      <LineLoader show={processing} />
      <section className="auth-page-left"></section>
      <section className="auth-page-right fade-in">
        {step === 1 ? (
          <form className="auth-form" onSubmit={handleGetOTP}>
            <div className="auth-form-header">
              <h1 className="auth-form-header-title">Sign in</h1>
              <div className="auth-form-header-subtitle">
                Enter your phone number to continue, we will send you an OTP to
                verify your identity.
              </div>
            </div>
            <div className="auth-form-group">
              <input
                type="tel"
                name="tel"
                placeholder="e.g 0712345678"
                autoComplete="on"
                onChange={handlePhoneChange}
                defaultValue={phone || ""}
                required
              />
              {validInput.phone ? null : (
                <InvalidInput message="Phone number is invalid" />
              )}
            </div>
            <div className="auth-form-group">
              <button
                disabled={processing || !validInput.phone}
                className="auth-form-btn"
                type="submit">
                {processing ? "Please wait" : "Get OTP"}
              </button>
            </div>
            <div style={{ display: "none" }} className="auth-from-terms">
              By signing in, you agree to our{" "}
              <a href="/terms" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="/privacy" target="_blank">
                Privacy Policy
              </a>
            </div>
            <div className="auth-form-footer">
              <div className="auth-form-footer-text">
                Don't have an account?{" "}
                <Link to="/signup" className="auth-form-footer-link">
                  Sign up
                </Link>
              </div>
            </div>
          </form>
        ) : (
          <form className="auth-form" onSubmit={handleVerifyOTP}>
            <div className="auth-form-header">
              <h1 className="auth-form-header-title">Verify OTP</h1>
            </div>
            <div className="auth-form-group">
              {validInput.code ? (
                <label className="auth-otp-label auth-form-label" htmlFor="otp">
                  {otpMessage}
                </label>
              ) : (
                <>
                  <p
                    style={{
                      marginBottom: "0.5rem",
                    }}>
                    <InvalidInput message={otpMessage} />
                  </p>
                </>
              )}
              <input
                type="text"
                name="otp"
                placeholder="e.g 123456"
                required
                maxLength={6}
                minLength={6}
                onChange={() => {
                  setOtpMessage("Please provide a valid OTP to continue");
                  setValidInput((prev) => ({ ...prev, code: true }));
                }}
              />
            </div>
            <div className="auth-btn-group">
              <button
                disabled={processing}
                className="auth-form-btn"
                type="submit">
                {processing ? "Please wait" : "Verify OTP"}
              </button>
              <button
                className="auth-form-btn auth-form-btn-cancel"
                type="button"
                onClick={() => {
                  setStep(1);
                  authService.clearRecaptchaVerifier();
                  setValidInput((prev) => ({ ...prev, code: true }));
                }}>
                Back
              </button>
            </div>
          </form>
        )}
      </section>
      <div id="recaptcha-container">
        <div id="recaptcha"></div>
      </div>
    </section>
  );
};

export default Signin;
