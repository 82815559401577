import axios from "axios";
import { DocumentData } from "firebase/firestore";
import { IBuyPayload, PayoutRequest, UseAPI } from "models";
import { dateFormatter, fb } from "modules";
import { pwa } from "pwafire";
class Api {
  /**
   * This method returns host api url.
   * @method getHostAPI
   */
  getHostAPI() {
    const hostname = window.location.hostname;
    const api =
      hostname === "localhost" || "dev.vouchpal.com"
        ? "https://dev-api.vouchpal.com"
        : hostname === "staging.vouchpal.com"
        ? "https://staging-api.vouchpal.com"
        : "https://api.vouchpal.com";
    return api;
  }
  /**
   * This method returns the current user's payment requests.
   * @method getPaymentRequests
   */
  getPaymentRequests(phone: string, callback: (data: PayoutRequest[]) => void) {
    const txRef = fb.collection(fb.db, "transactions");
    const q = fb.query(
      txRef,
      fb.where("payer", "==", phone),
      fb.where("type", "==", "payout"),
      fb.where("status", "==", "pending"),
      fb.orderBy("date_created", "desc")
    );
    fb.onSnapshot(q, (querySnapshot) => {
      const data: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        data.push({
          id: doc.id,
          ...doc.data(),
          date: dateFormatter(doc.data().date_created),
        });
      });
      data.length > 0 ? pwa.setBadge(data.length) : pwa.clearBadge();
      const res: PayoutRequest[] = data.map((doc) => {
        return {
          amount: doc.amount,
          date: dateFormatter(doc.date_created),
          payee: doc.payee,
          status: doc.status,
          token: doc.token,
          ref: doc.id,
        };
      });
      callback(res);
    });
  }

  /**
   * This method handles get requests to the api.
   * @method getData
   */
  async getData({
    url,
    method,
    headers = { "Content-Type": "application/json" },
    body = {},
  }: {
    url: string | null;
    method: "get" | "post" | "put" | "delete";
    headers?: { [key: string]: string };
    body?: { [key: string]: any };
  }) {
    try {
      if (url && method) {
        const res = await axios({
          method: method,
          url: url,
          data: body ? JSON.stringify(body) : JSON.stringify({}),
          headers: headers,
        });
        return res.data as UseAPI;
      } else {
        throw new Error("URL or Method is not defined");
      }
    } catch (error: any) {
      throw error && error.response ? error.response.data : error;
    }
  }

  /**
   * This method checks id phone number already exists.
   * @method checkPhoneNumber
   */
  async checkPhoneNumber(
    phone: string,
    callback: (data: { ok: boolean }) => void
  ) {
    try {
      const res = await axios.get(`${this.getHostAPI()}/phones/${phone}`);
      callback(res.data);
    } catch (error) {
      throw error;
    }
  }

  async buyToken(
    data:
      | IBuyPayload
      | {
          transaction_ref: string;
          token_ref: string;
          merchant_request_id: string;
        },
    authToken: string,
    callback: (res: any) => void
  ) {
    try {
      const res = await axios.post(`${this.getHostAPI()}/buy`, data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${authToken}`,
        },
      });
      callback(res.data);
    } catch (error) {
      throw error;
    }
  }
}

export const apiService = new Api();
