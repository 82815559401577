import { useState } from "react";
import { BoxCard } from "components";
import "./category.css";

const CategoryComponent = ({
  categories,
  setPreview,
}: {
  categories: any[];
  setPreview: any;
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <section className="categories-section">
      <div className="search-bar">
        <input
          type="text"
          className="search-txt search-input"
          placeholder="Search..."
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </div>
      <div className="categories-container">
        {categories
          .filter((category: { name: string }) => {
            if (searchTerm === "") {
              return categories;
            } else if (
              category.name
                .toLocaleLowerCase()
                .includes(searchTerm.toLocaleLowerCase())
            ) {
              return category;
            }
            return null;
          })
          .map((category: { id: any }) => (
            <BoxCard
              key={category.id}
              category={category}
              setPreview={setPreview}
            />
          ))}
      </div>
    </section>
  );
};

export default CategoryComponent;
