import { AuthContext } from "contexts";
import { TokenResponse } from "models";
import { fb } from "modules";
import { useContext, useEffect, useState } from "react";

const useTokensCollection = () => {
  const { currentUser } = useContext(AuthContext);

  const [data, setData] = useState<{
    sent: TokenResponse[];
    received: TokenResponse[];
  }>({
    sent: [],
    received: [],
  });
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        if (currentUser && currentUser.phoneNumber) {
          const q = fb.query(
            fb.collection(fb.db, "tokens"),
            fb.where(
              "owners",
              "array-contains",
              currentUser.phoneNumber?.toString()
            ),
            fb.where("used", "==", false),
            fb.where("valid", "==", true),
            fb.orderBy("date_created", "desc")
          );
          fb.onSnapshot(q, (querySnapshot) => {
            const list: any[] = [];
            querySnapshot.forEach((doc) => {
              const {
                to,
                from,
                cid,
                balance,
                locked,
                partner,
                open,
                token,
                note,
                value,
                date_created,
              } = doc.data();
              list.push({
                id: doc.id,
                to,
                from,
                cid,
                balance,
                locked,
                partner,
                open,
                token,
                note,
                value,
                date_created,
              });
            });
            setData({
              sent: list.filter(
                (item) => item.from.phone === currentUser.phoneNumber
              ),
              received: list.filter((item) =>
                item.to.includes(currentUser.phoneNumber)
              ),
            });
            setLoading(false);
          });
        } else {
          setData({
            sent: [],
            received: [],
          });
          setLoading(false);
        }
      } catch (error) {
        setError(error);
        setLoading(false);
        setData({
          sent: [],
          received: [],
        });
      }
    })();
  }, [currentUser]);
  return [data, loading, error];
};

export default useTokensCollection;
