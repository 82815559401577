import { useContext } from "react";
import { Loader } from "..";
import { AuthContext } from "../../contexts";
import { PayoutRequest } from "../../models";

const Message = ({
  message,
  count,
  current,
  loading,
}: {
  message: PayoutRequest;
  count: number;
  current: number;
  loading?: boolean;
}) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <div className="message-container">
      {loading ? (
        <div className="inbox-loading">
          <Loader size={24} strokeWidth={2.75} />
          <span className="inbox-loading-label">Please wait...</span>
        </div>
      ) : count === 0 ? (
        <div className="inbox-no-requets">
          You have zero voucher payout requests to process
        </div>
      ) : (
        <div className="message-container">
          <div style={{ marginTop: 5 }} className="grey-text">
            <span>
              {current} / {count}
            </span>
            <span style={{ marginLeft: 10 }}>{message.date.time_since}</span>
          </div>
          <div className="inbox-request-label">
            {message.payee === currentUser?.phoneNumber ? (
              <span>
                Approve <b>KSH {message.amount.toLocaleString()}</b> payout
                request to <b>Yourself</b>
              </span>
            ) : (
              <span>
                Approve <b>KSH {message.amount.toLocaleString()}</b> payout
                request to
                <b style={{ color: "#259c8c", marginLeft: 5 }}>
                  {message.payee}
                </b>
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
