import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./not-found.css";

const NotFound = () => {

  useEffect(() => {
    document.title = `Sorry - Not found`;
  }, []);
  return (
    <main className="not-found">
      <section className="not-found-section">
        <span>404</span>
        <p>The requested path could not be found</p>
        <Link to="/" className="not-found-back">
          home
        </Link>
      </section>
    </main>
  );
};

export default NotFound;
