import moment from "moment";

// Calculate time since...
const calculateTimeSince = (date: moment.MomentInput) => {
  let then = moment(date);
  let now = moment();
  if (now.diff(then, "minutes") < 60)
    return now.diff(then, "minutes") + "m ago";
  if (now.diff(then, "hours") < 24) return now.diff(then, "hours") + "h ago";
  return now.diff(then, "days") + "d ago";
};

const dateFormatter = (timestamp: any) => {
  const date = timestamp.seconds ? timestamp.toDate() : timestamp;
  const validDate: any = new Date(date);
  // Set UTC + 3 for firestore timestamp...
  if (timestamp._seconds) validDate.setHours(validDate.getHours() + 3);
  // Set date...
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const created_on = validDate.toLocaleDateString(undefined, options);
  const created_at = validDate.toLocaleTimeString();
  return {
    created_at,
    created_on,
    time_since: calculateTimeSince(date),
  };
};

export default dateFormatter;
