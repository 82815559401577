import { Wallet } from "models";
import { fb } from "modules";
import { useEffect, useState } from "react";

const useWalletCollection = ({ uid }: { uid: string }) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    (async () => {
      try {
        if (uid) {
          fb.onSnapshot(fb.doc(fb.db, "wallets", uid), (doc) => {
            doc.exists() ? setData(doc.data() as Wallet) : setData(null);
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        error instanceof Error ? setError(error.message) : setError(error);
        setLoading(false);
      }
    })();
    return () => {
      setData([]);
      setLoading(true);
      setError(null);
    };
  }, [uid]);
  return [data, loading, error];
};

export default useWalletCollection;
