import { ArrowLeftIcon, ChevronRightIcon } from "@primer/octicons-react";
import { BalanceComponent, Sidebar, Summary } from "components";
import { AuthContext } from "contexts/auth";
import { useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";

const Requests = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      document.title = `Activity - ${currentUser.displayName}`;
    }
  }, [currentUser]);

  return (
    <section className="page-view">
      <section className="flex">
        <section className="flex-nav">
          <Sidebar user={currentUser} />
        </section>
        <section className="flex-left">
          <div className="inner-left">
            <div className="left-header">
              <NavLink className="account-page-flexbox" to="/transactions">
                <ArrowLeftIcon size={24} />
                <div className="account-page-back">
                  {" "}
                  <div className="left-h1">Requests</div>
                </div>
              </NavLink>
            </div>
            <section className="wallet fade-in">
              <Link
                to="/transactions/requests/sent"
                className="wallet-nav-item">
                <span className="wallet-nav-item-left">Sent</span>{" "}
                <span className="wallet-nav-item-right">
                  <ChevronRightIcon size={24} />
                </span>
              </Link>
              <Link
                to="/transactions/requests/received"
                className="wallet-nav-item">
                <span className="wallet-nav-item-left">Received</span>
                <span className="wallet-nav-item-right">
                  <ChevronRightIcon size={24} />
                </span>
              </Link>
            </section>
          </div>
        </section>
        <section className="flex-right">
          <section className="flex-container">
            <section className="right-top">
              <div className="top-inner">
                <BalanceComponent />
              </div>
            </section>
            <Summary title="Purchased" />
          </section>
        </section>
      </section>
    </section>
  );
};

export default Requests;
