import { addDays, addHours, format } from "date-fns";

class DateService {
  today(type: "date" | "time" | "datetime" = "date") {
    switch (type) {
      case "date":
        return format(new Date(), "yyyy-MM-dd");
      case "time":
        return format(new Date(), "HH:mm:ss");
      case "datetime":
        return format(new Date(), "yyyy-MM-dd HH:mm:ss");
      default:
        return format(new Date(), "yyyy-MM-dd");
    }
  }

  addDays(days: number) {
    return format(addDays(new Date(), days), "yyyy-MM-dd");
  }

  addHours(hours: number) {
    return format(addHours(new Date(), hours), "yyyy-MM-dd HH:mm:ss");
  }

  format(date: Date, type: "date" | "time" | "datetime" = "date") {
    switch (type) {
      case "date":
        return format(date, "yyyy-MM-dd");
      case "time":
        return format(date, "HH:mm:ss");
      case "datetime":
        return format(date, "yyyy-MM-dd HH:mm:ss");
      default:
        return format(date, "yyyy-MM-dd");
    }
  }

  toString(date: string, type: "date" | "time" | "datetime" = "date") {
    const dateObj = new Date(date);
    switch (type) {
      case "date":
        return format(dateObj, "PPPP");
      case "time":
        return format(dateObj, "p");
      case "datetime":
        return format(dateObj, "PPPPp");
      default:
        return format(dateObj, "PPPP");
    }
  }
}

export const dateService = new DateService();
