const ActionButton = ({
  display = "",
  label = "Action",
  disabled = false,
  type = "button",
  className = "",
  onClick = () => {},
  inProgress = false,
}: {
  display?: string;
  label?: string;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
  className?: string;
  onClick?: (event: any) => void;
  inProgress?: boolean;
}) => {
  return (
    <button
      disabled={disabled || inProgress}
      type={type}
      className={className}
      onClick={onClick}
      style={{ display: display }}>
      {inProgress ? "Wait.." : label || "Action"}
    </button>
  );
};

export { ActionButton };
