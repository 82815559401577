import { NavLink } from "react-router-dom";
import { CSSProperties, FC } from "react";
import { TableIcon, HomeIcon, PersonIcon } from "@primer/octicons-react";
import "./navbar.css";

const Bottomnav: FC<{ style?: CSSProperties }> = (props) => (
  <div className="nav" style={props.style}>
    <ul className="navList reset">
      <li className="nav-item">
        <span className="select-icon">
          <NavLink to="/transactions">
            <TableIcon size={24} />
            <span className="nav-item-label">Activity</span>
          </NavLink>
        </span>
      </li>
      <li className="nav-item like-btn">
        <span className="select-icon">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "selected" : "")}>
            <HomeIcon size={24} />
            <span className="nav-item-label">Home</span>
          </NavLink>
        </span>
      </li>
      <li className="nav-item">
        <span className="select-icon">
          <NavLink
            to="/account"
            className={({ isActive }) => (isActive ? "selected" : "")}>
            <PersonIcon size={24} />
            <span className="nav-item-label">Account</span>
          </NavLink>
        </span>
      </li>
    </ul>
  </div>
);

export default Bottomnav;
