import { CheckCircleIcon, CircleIcon } from "@primer/octicons-react";
import { MouseEventHandler, useEffect, useState } from "react";
import styled from "styled-components";

const RadioBtn = ({
  className,
  checked,
  label,
  onClick,
}: {
  className?: string;
  checked?: boolean;
  label?: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}) => {
  return (
    <div className={className} onClick={onClick}>
      {checked ? <CheckCircleIcon className="radio-checked" /> : <CircleIcon />}
      <div className="radio-label">{label || "Label"}</div>
    </div>
  );
};

const Radio = styled(RadioBtn)`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  gap: 6px;
  padding: 6px 15px;
  border-radius: 25px;
  border: 1.5px solid var(--border-color);
  user-select: none;
  font-size: 15px;

  .radio-checked {
    color: var(--green-color);
  }

  &.radio-btn--active {
    border: 2px solid #d8eee0;
    color: var(--green-color);
  }

  &:hover {
    .radio-label {
      color: var(--green-color);
    }
  }
`;

const GroupedRadios = styled(
  ({
    className,
    items,
    onChange,
    style = {},
  }: {
    className?: string;
    items: {
      label: string;
      value: string;
      default?: boolean;
    }[];
    onChange: (value: any) => void;
    style?: React.CSSProperties;
  }) => {
    const [selected, seSelected] = useState({
      label: "",
      value: "",
    });

    useEffect(() => {
      const defaultItem = items.find((item) => item.default);
      if (defaultItem && selected.value === "") {
        seSelected(defaultItem);
      }
    }, [items, selected]);

    return (
      <div style={style} className={className}>
        {items.map((item, index) => (
          <Radio
            key={index}
            checked={selected.value === item.value}
            className={selected.value === item.value ? "radio-btn--active" : ""}
            label={item.label}
            onClick={() => {
              seSelected(item);
              onChange(item.value);
            }}
          />
        ))}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  &:hover {
    .radio-label {
    }
  }
`;

export { Radio, GroupedRadios };
