import { createRoot } from "react-dom/client";

import "assets/styles/blinker.css";
import "assets/styles/bottom.css";
import "assets/styles/fade-in.css";
import "assets/styles/form.css";
import "assets/styles/home.css";
import "assets/styles/label.css";
import "assets/styles/pagination.css";
import "assets/styles/toast.css";
import "assets/styles/wallet.css";

import { AuthProvider, MainContextProvider } from "contexts";
import {
  AccountPage,
  AccountSettingsPage,
  EmailPage,
  HomePage,
  NamePage,
  NotFoundPage,
  ProfilePage,
  PurchaseStatusPage,
  RequestsTransactionsPage,
  Signin,
  Signup,
  Transactions,
  TransactionsPage,
} from "pages";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "reportWebVitals";
import PrivateRoute from "routes";

import "./index.css";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <AuthProvider>
    <MainContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup/" element={<Signup />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/purchase/confirmation"
              element={<PurchaseStatusPage />}
            />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/transactions" element={<TransactionsPage />} />
            <Route
              path="/transactions/requests"
              element={<RequestsTransactionsPage />}
            />
            <Route
              path="/transactions/requests/sent"
              element={<Transactions />}
            />
            <Route
              path="/transactions/requests/received"
              element={<Transactions />}
            />
            <Route path="/transactions/purchases" element={<Transactions />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/account/profile" element={<AccountSettingsPage />} />
            <Route path="/account/profile/name" element={<NamePage />} />
            <Route path="/account/profile/email" element={<EmailPage />} />
          </Route>
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </MainContextProvider>
  </AuthProvider>
);

reportWebVitals();
